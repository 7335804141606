import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import CanIUse from '../components/CanIUse';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
        noPic
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "date": "2017-01-28",
  "title": "position: sticky is Amazing",
  "promo": "flexbox",
  "description": "what you can build with CSS position:sticky, how to use it, and what to watch out for.",
  "cover": "/img/cover/gecko.jpg",
  "noPic": true,
  "color": "#9cfa61"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`CSS just got a sweet little upgrade. `}<inlineCode parentName="p">{`position:sticky`}</inlineCode>{` just landed in Chrome 56. Sticky positioning in CSS lets us build some really neat interactions in very few lines of code. It's useful for any time you want a UI element to stick around in view as the user is scrolling, but not become sticky until the element gets to a `}<em parentName="p">{`specific distance`}</em>{` from the top/bottom/left/right egde of the scrolling viewport. It's like a `}<inlineCode parentName="p">{`position:fixed`}</inlineCode>{` element that's a `}<strong parentName="p">{`sleeper agent spy`}</strong>{`. It behaves just like a regular `}<inlineCode parentName="p">{`position:relative`}</inlineCode>{` element - even fooling its own parents and siblings - until the secret distance is met, activatating the `}<inlineCode parentName="p">{`position:fixed`}</inlineCode>{` behavior of the spy.`}</p>
    <h2>{`What Can We Build With It?`}</h2>
    <p>{`Sticky position is perfect for things like the iOS style list headings. Scroll the content and watch the headings stick once they hit `}<inlineCode parentName="p">{`0px`}</inlineCode>{` from the `}<inlineCode parentName="p">{`top`}</inlineCode>{` edge.`}</p>
    <CodeExample live={true} showCSS={`
  .heading{
    background: #ccc;
    height: 50px;
    line-height: 50px;
    margin-top: 10px;
    font-size: 30px;
    padding-left: 10px;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
  }   
`} fixedCSS={`
  .app {
    overflow: auto;
    height: 250px;
    border: 5px solid black;
  }
`} html={`
<div class="app">
  <h1>Animals by Alphabet</h1>
  <div class="container">
    <div class="heading">A</div>
    <div>American Buffalo</div>
    <div>Aardvark</div>
    <div>Alligator</div>  
    <div>Antelope</div>    
    <div class="heading">B</div>
    <div>Baboon</div>
    <div>Bat</div>
    <div>Blue Bird</div>
    <div class="heading">C</div>
    <div>Cat</div>
    <div>Camel</div>
    <div>Chicken</div>  
    <div>Chipmunk</div>    
    <div class="heading">D</div>
    <div>Dog</div>
    <div>Donkey</div>
    <div>Dave</div>  
    <div>Duck</div>      
  </div>   
</div>
`} mdxType="CodeExample" />
    <p>{`Or say you're building a Trello replacement now that they've been acquired by Atlassian (Jira) (<— sadness in my heart). And you want the list headers to stay visible if the user scrolls down. You also want the "add item" footers to be visible if they scroll up. Try it! Scroll up and down and watch how both of those elements stick around once they reach the edge of the viewport:`}</p>
    <CodeExample live={true} showCSS={`
  header{
    background: #ccc;
    font-size: 20px;
    color: #282a37;
    padding: 10px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }\n
  footer{
    background: #ccc;
    padding: 10px;
    color: #ae81fe;
    position: relative;
    position: -webkit-sticky;
    position: sticky;
  }\n
  footer{
    bottom: 0;
  }\n
  list{
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 200px;
    margin-left: 20px;
    background: #282a37;
  }\n
  item{
    padding: 10px;
    color: #fff;
    display: block;
  }\n
  body{
    padding-top: 20px;
    display: flex;
    align-items: flex-start;
  }\n
  .abs{
    position: absolute;
    right: 0;
    top: 10px;
  }
`} fixedCSS={`
  .app {
    overflow: auto;
    height: 200px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 20px;
    border: 5px solid black;
  }
`} html={`
<div class="app">
  <list>
    <header>Books</header>
    <item>The 10x Rule</item>
    <item>Ego is the Enemy</item>  
    <item>Flow</item>    
    <item>Minimalism</item>
    <footer>+ add book</footer>
  </list>
  <list>
    <header>Movies</header>
    <item>Star Wars</item>
    <item>Moana</item>  
    <item>Princess Bride</item>    
    <item>Avatar</item>
    <item>Doctor Strange</item>  
    <item>Dumb & Dumber</item>  
    <footer>+ add movie</footer>
  </list>
  <list>
    <header>Games</header>
    <item>Star Citizen</item>
    <item>Astroneer</item>  
    <item>Starcraft</item>    
    <footer>+ add game</footer>
  </list>
</div>
`} mdxType="CodeExample" />
    <p>{`You can also stick items to the left or right edges. Here is a sidescrolling image viewer, with rotated text descriptions of the images. Scroll it sideways and watch as the descriptions dock to the left, in view, until a new description pushes it out of the way.`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
  div[description]{
    max-width: 40px;
    height: 300px;
    position: -webkit-sticky;
    position: sticky;
    left: 0; /* become sticky once touching left edge */
  }\n
  sidescroller{
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    background: #000;
  }\n
  div[wrapper]{
    flex: 0 0 40px;
    max-width: 40px;
    height: 300px;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    white-space: nowrap;
    color: #fff;
  }\n
  div[item]{
    display: flex;
  }\n
  div[description] span{
    display: inline-block;
    background: rgba(0,0,0,.5);
    width: 300px;
    height: 40px;
    transform: rotate(-90deg) translateX(-300px);
    transform-origin: left top 0;
    padding-top: 11px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-size: 14px;
  }\n
  img{
    max-height: 300px;
  }
`} fixedCSS={`
  body *{
    font-family: Arial;
  }     
`} html={`
  <sidescroller>
    <div item>
      <div description><span>Clear Beach</span></div>
      <img src="https://gedd.ski/img/sticky/beach.jpg"></img>
    </div>
    <div item>
      <div description><span>Gummy Bears Yay!</span></div>
      <img src="https://gedd.ski/img/sticky/gummy.jpg"></img>
    </div>
    <div item>
      <div description><span>Kids</span></div>
      <img src="https://gedd.ski/img/sticky/kids.jpg"></img>
    </div>
    <div item>
      <div description><span>Wolf Pup</span></div>
      <img src="https://gedd.ski/img/sticky/pup.jpg"></img>
    </div>
    <div item>
      <div description><span>Old Dude</span></div>
      <img src="https://gedd.ski/img/sticky/oldman.jpg"></img>
    </div>
  </sidescroller>   
`} mdxType="CodeExample" />
    <p>{`edit on `}<a parentName="p" {...{
        "href": "https://codepen.io/geddski/pen/rjYpVr"
      }}>{`codepen`}</a></p>
    <p>{`You can even specify `}<strong parentName="p">{`negative`}</strong>{` numbers when you want an element to become sticky once part or all of it is scrolled out of view! This could be useful for example with a sidebar menu that becomes sticky right when it's scrolled out of view, leaving a small button visible that when clicked could jump back to the sidebar:`}</p>
    <CodeExample live={true} showCSS={`
  .sidebar{
    background: purple;
    width: 200px;
    height: 300px;
    padding: 20px;
    flex-shrink: 0;
    overflow: visible;
    position: -webkit-sticky;
    position: sticky;
    left: -200px;
  }\n
  .sidebar .handle{
    height: 30px;
    width: 30px;
    position: absolute;
    right: -30px;
    top: 0;
    background: purple;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    padding-left: 8px;
    cursor: pointer;
  }\n
  p{
    padding: 20px;
  }\n
  .site{
    display: flex;
  }
`} fixedCSS={`
  body, html {
    margin: 0;
    padding: 0;
  }
  body * {
    box-sizing: border-box;
  }
`} js={`
function sidebar(){
  window.scrollTo(0, 0);
}
`} html={`
<div class="site">
  <div class="sidebar">
    <span>sidebar</span>
    <div class="handle" onclick="sidebar()"><</div>
  </div>
   <p>some content</p> 
   <p>some content</p> 
   <p>some content</p> 
   <p>some content</p> 
   <p>some content</p> 
   <p>some content</p> 
   <p>some content</p> 
   <p>some content</p>   
   <p>some content</p> 
   <p>some content</p> 
   <p>some content</p> 
   <p>some content</p> 
   <p>some content</p> 
   <p>some content</p> 
   <p>some content</p> 
   <p>some content</p>   
</div>   
`} mdxType="CodeExample" />
    <p>{`edit on `}<a parentName="p" {...{
        "href": "https://codepen.io/geddski/pen/LxOedE"
      }}>{`codepen`}</a></p>
    <h2>{`Give Me This Power`}</h2>
    <p>{`Pretty neat right? And using it is straightforward.`}</p>
    <ol>
      <li parentName="ol">{`Declare the element as sticky with `}<inlineCode parentName="li">{`position:sticky`}</inlineCode>{` (plus any browser prefixes needed like `}<inlineCode parentName="li">{`position: -webkit-sticky`}</inlineCode>{`)`}</li>
      <li parentName="ol">{`Specify an edge (top | right | bottom | left) for the item to "stick" to.`}</li>
      <li parentName="ol">{`Enter a distance from said edge that when reached will activate the stickiness.`}</li>
    </ol>
    <p>{`For example, say you want a header that becomes sticky once it gets 20px away from the top of the scroll area:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.header {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}
`}</code></pre>
    <p>{`Or the menu that sticks to the left edge once scrolled out of view like the example above:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.menu {
  width: 200px;
  position: -webkit-sticky;
  position: sticky;
  left: -200px;
}
`}</code></pre>
    <h2>{`Some Gotchas`}</h2>
    <p><inlineCode parentName="p">{`position:sticky`}</inlineCode>{` has a few gotchas you'll want to watch out for.`}</p>
    <h3>{`Siblings`}</h3>
    <p>{`If you set sibling (adjacent) elements to `}<inlineCode parentName="p">{`position: sticky`}</inlineCode>{`, they'll behave slightly differently from elements inside of nested items. Sticky sibling elements `}<strong parentName="p">{`won't move out of the way`}</strong>{` for new elements. Instead they'll overlap in place:`}</p>
    <img width="1162" src="/img/sticky/siblings.png" />
    <p>{`Sometimes you might want this behavior, but if you do be sure to set a `}<em parentName="p">{`background color`}</em>{` otherwise the user will see all the items at once packed into the same little space and it will look like a mess.`}</p>
    <p>{`On the other hand, if you `}<strong parentName="p">{`nest`}</strong>{` the sticky elements into `}<strong parentName="p">{`parent elements`}</strong>{` like we did in the sidescroller example, then the sticky elements will begin to move out of the way as soon as another sticky element begins to touch it. This is a good practice and the effect is a bit classier IMO:`}</p>
    <img width="1162" src="/img/sticky/moves.gif" />
    <h3>{`Overflow`}</h3>
    <p>{`Don't try to use `}<inlineCode parentName="p">{`overflow: auto|scroll|hidden`}</inlineCode>{` on the parent element of a `}<inlineCode parentName="p">{`position:sticky`}</inlineCode>{` element. It completely breaks the stickiness. `}<inlineCode parentName="p">{`overflow: visible`}</inlineCode>{` is fine.`}</p>
    <h3>{`Absolute Positioning`}</h3>
    <p>{`If you're wanting to use `}<inlineCode parentName="p">{`position:absolute`}</inlineCode>{` on an element `}<em parentName="p">{`inside`}</em>{` of a sticky element you have to be careful. If your app is running in an `}<em parentName="p">{`older browser`}</em>{` that doesn't support `}<inlineCode parentName="p">{`position:sticky`}</inlineCode>{`, then that sticky element won't act like a relative positioned element. So the absolute positioned element will `}<em parentName="p">{`skip it`}</em>{` and look up the DOM tree until it finds the next non-static element (absolute | relative | fixed position), defaulting to the `}<inlineCode parentName="p">{`html`}</inlineCode>{` element if none found. In other words, your absolute positioned element is going to be in a way different place on the screen than you expected it to be. One might think the solution for this is to just set `}<em parentName="p">{`both`}</em>{` relative and sticky positioning if you are building something for older browsers:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* WARNING don't do this */
.footerWithAbsolutePositionedChildren {
  position: relative; /* <-- all browsers will set this */
  position: sticky; /* <-- new browsers will use this, old ones will ignore it */
  bottom: 20px;
}
`}</code></pre>
    <p>{`Good right? Nope, this is bad idea because of `}<em parentName="p">{`non-zero`}</em>{` sticky numbers. If `}<inlineCode parentName="p">{`position:sticky`}</inlineCode>{` isn't supported in a browser, your footer will stay with the `}<inlineCode parentName="p">{`position: relative`}</inlineCode>{` you specified first. So now that edge value you specified for stickiness is now going to count as a `}<strong parentName="p">{`relative`}</strong>{` value. Which in this case means pushing the footer up 20px which is not at all what we intended.`}</p>
    <p>{`The better solution is to use the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/@supports"
      }}>{`CSS supports at-rule`}</a>{` to detect if the current browser supports sticky positioning, and if so `}<em parentName="p">{`then`}</em>{` set the edge value:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.footerWithAbsolutePositionedChildren {
  position: relative; /* don't forget this */
}

/* NOTE: @supports has to be at the root, not nested */
@supports (position: sticky) {
  .footerWithAbsolutePositionedChildren {
    position: sticky;
    bottom: 20px; /* now this won't mess with positioning in non-sticky browsers */
  }
}
`}</code></pre>
    <h2>{`Why Not Use JavaScript?`}</h2>
    <p>{`You could definitely implement this is JS. But that would involve a `}<inlineCode parentName="p">{`scroll`}</inlineCode>{` event listener, which is still a very expensive thing to add to your app. Scrolling is one of the most frequent actions your users perform, and executing JavaScript during those events makes it hard to maintain a solid 60 FPS (frames per second) scroll. The UI becomes out of sync with the user's mouse/finger/stylus. This is called scroll jank. There is a special kind of event listener called `}<a parentName="p" {...{
        "href": "https://caniuse.com/#feat=passive-event-listener"
      }}>{`passive event listeners`}</a>{` that lets the browser know your event won't stop scrolling, so the browser can optimize these events a lot more. But they're not supported yet in IE or Edge where you'd want a JS fallback approach anyway.`}</p>
    <p>{`Additionally, with `}<inlineCode parentName="p">{`position:sticky`}</inlineCode>{` you aren't writing to the DOM during scrolling, so you won't be causing any forced layouts & layout recalculations. As a result, the browser is able to move this operation to the GPU and you get `}<strong parentName="p">{`very`}</strong>{` smooth scrolling even when sticky elements are in play. It's especially smooth in mobile Sarari.`}</p>
    <p>{`Plus it's simply easier to write two lines of declarative CSS than the JS alternatives.`}</p>
    <h2>{`Can I Use This Now?`}</h2>
    <p><inlineCode parentName="p">{`position:sticky`}</inlineCode>{` is supported in all evergreen browsers. IE doesn't matter at this point unless you're contractually obligated in Enterprise Town (thanks, Sales). There are many polyfills out there if you absolutely have to have this behavior, but they all use JavaScript so you'll take the performance hit mentioned above. A better option is to design your app so that sticky position is a slick addition, but the app still functions without it. So I give it a thumbs up.`}</p>
    <CanIUse feature="css-sticky" mdxType="CanIUse" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      